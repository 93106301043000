<template>
    <transition name="fade">
        <div>
            <div class="modal-backdrop show"></div>
            <div class="modal" tabindex="-1" style="display:block">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                {{ modal_title }}
                            </h5>
                            <button type="button" class="btn-close" @click="close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row align-items-center mb-3">
                                <div class="col-md-3">
                                    <span class="title fs-6">名前</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="title fs-6">依頼ステータス</span>
                                </div>
                                <div class="col-md-6">
                                    <span class="title fs-6">備考</span>
                                </div>
                                <div class="col-md-3">
                                    <span class="title fs-6">更新者</span>
                                </div>
                                <div class="col-md-3">
                                    <span class="title fs-6">更新日</span>
                                </div>
                                <div class="col-md-5">
                                    <span class="title fs-6">スケジュール</span>
                                </div>
                            </div>
                            <template v-for="partner_invitation in partner_invitations" :key="partner_invitation">
                                <div class="row align-items-center mb-2">
                                    <div class="col-md-3">
                                        <span class="badge bg-secondary me-1">{{partner_invitation.partner.cancel_number}}</span>
                                        <span>{{ partner_invitation.partner.photographer_name }}</span>
                                    </div>
                                    <div class="col-md-4">
                                        <form-select
                                            v-model="partner_invitation.invitation_status"
                                            :options="invitation_statuses"
                                            empty_option="-- 未確認 --"
                                        ></form-select>
                                    </div>
                                    <div class="col-md-6">
                                        <form-input
                                            v-if="partner_invitation.invitation_status"
                                            v-model="partner_invitation.memo"
                                        ></form-input>
                                    </div>
                                    <div class="col-md-3">
                                        {{ partner_invitation.employee.photographer_name }}
                                    </div>
                                    <div class="col-md-3">
                                        {{ partner_invitation.input_date_display }}
                                    </div>
                                    <div class="col-md-5">
                                        <template v-if="partner_invitation.schedule_names.length">
                                            <template v-for="schedule in partner_invitation.schedule_names" :key="schedule">
                                                <span style="font-size:.9rem;"><i class="bi me-1" :class="schedule.schedule_icon"></i></span>
                                                {{ schedule.schedule_name_display }}<br>
                                            </template>
                                        </template>
                                        <template v-else>
                                            予定なし
                                        </template>
                                    </div>
                                </div>
                            </template>
                            <hr>
                            <div class="row">
                                <div class="col-md-4">
                                    <form-select
                                        v-model="additional_other_partner.photographer_id"
                                        :options="options_other_partner"
                                        empty_option="-- 選択 --"
                                    ></form-select>
                                </div>
                                <div class="col-md-4">
                                    <form-select
                                        v-model="additional_other_partner.invitation_status"
                                        :options="invitation_statuses"
                                        empty_option="-- 選択 --"
                                    ></form-select>
                                </div>
                                <div class="col-md-12">
                                    <button-exec
                                        text="追加"
                                        icon="bi-plus-lg"
                                        color="btn-outline-primary"
                                        @click="addInvitation"
                                    >
                                    </button-exec>
                                </div>
                            </div>
                            </div>
                        <div class="modal-footer">
                            <button-exec-update
                                @click="save"
                            >
                            </button-exec-update>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import FormSelect from '@/components/forms/FormSelect';
import FormInput from '@/components/forms/FormInput';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate';
import ButtonExec from '@/components/buttons/ButtonExec';
import Invitation from '@/models/entities/invitation';
import Employee from '@/models/entities/employee';
import InvitationStatus from '@/models/enums/schedule/invitation-status';
import PartnerRank from '@/models/enums/partner/partner-rank';
import Schedule from '@/models/entities/schedule';
import { now as dateNow } from '@/utilities/date-format';

export default {
    name: 'InvitationModal',
    components: {
        FormSelect,
        FormInput,
        ButtonExecUpdate,
        ButtonExec
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage',
    ],
    props: {
        modelValue: {
            type: Object
        },
        invitation_partners: {
            type: Array,
            default: () => ([])
        },
        other_partners: {
            type: Array,
            default: () => ([])
        },
        options_other_partner: {
            type: Array,
            default: () => ([])
        },
        schedules: {
            type: Array,
            default: () => ([])
        },
        date: {
            type: String,
        },
        invitations_update_at: {
            type: String,
        }
    },
    emits: [
        'update:modelValue',
        'close',
        'update'
    ],
    data() {
        return {
            invitations: [],
            partner_invitations: [],
            last_invitations_update_at: this.invitations_update_at, // 自分の編集では競合しないため記録しておく

            additional_other_partner: {
                photographer_id: null,
                invitation_status: null,
            },

            invitation_statuses: InvitationStatus.options(),
        }
    },
    mounted() {
        this.mergePartnersAndInvitations();
    },
    watch: {

    },
    computed: {
        modal_title() {
            let date = this.$helper.nj(this.date);
            let invitation_count = this.partner_invitations.filter(partner_invitation => partner_invitation.invitation_status === InvitationStatus.DONE).length;

            return `${date} 応援カメラマン（${invitation_count}名）`;
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        // スケジュール名抽出
        extractScheduleName(partner) {
            let schedule_names_array = [];
            for (let schedule of this.schedules) {
                if (
                    schedule.photographers.some(schedule_photographer => schedule_photographer.photographer.photographer_id === partner.photographer_id)
                ) {
                    schedule_names_array.push(new Schedule(schedule));
                }
            }

            return schedule_names_array;
        },
        // 応援カメラマンと依頼一覧を混ぜる
        mergePartnersAndInvitations(invitations = this.modelValue) {
            this.invitations = invitations.map((invitation) => new Invitation(invitation));

            // ランク１、２の応援カメラマン
            for (let invitation_partner of this.invitation_partners) {
                let partner_invitation = {
                    invitation_id: null,
                    invitation_status: null,
                    partner: null,
                    employee: null,
                    input_date_display: null,
                    memo: null,
                    schedule_names: this.extractScheduleName(invitation_partner),
                }

                let index = this.invitations.findIndex(invitation => parseInt(invitation.partner.photographer_id, 10) === parseInt(invitation_partner.photographer_id, 10));

                if (index > -1) {
                    partner_invitation.invitation_id = this.invitations[index].invitation_id;
                    partner_invitation.invitation_status = this.invitations[index].invitation_status;
                    partner_invitation.partner = this.invitations[index].partner;
                    partner_invitation.employee = this.invitations[index].employee;
                    partner_invitation.input_date_display = this.invitations[index].input_date_display;
                    partner_invitation.memo = this.invitations[index].memo;

                } else {
                    partner_invitation.partner = invitation_partner;
                    partner_invitation.employee = new Employee();
                }

                this.partner_invitations.push(partner_invitation);
            }

            // ランク３以下の応援カメラマンが依頼一覧に入っている場合
            let other_partner_invitations = this.invitations.filter(invitation => invitation.partner.partner_rank > PartnerRank.SEMI_REGULAR);

            if (other_partner_invitations.length) {
                for (let other_partner_invitation of other_partner_invitations) {
                    let partner_invitation = {
                        invitation_id: other_partner_invitation.invitation_id,
                        invitation_status: other_partner_invitation.invitation_status,
                        partner: other_partner_invitation.partner,
                        employee: other_partner_invitation.employee,
                        input_date_display: other_partner_invitation.input_date_display,
                        memo: other_partner_invitation.memo,
                        schedule_names: this.extractScheduleName(other_partner_invitation.partner),
                    }

                    this.partner_invitations.push(partner_invitation);
                }
            }
        },
        // ランク３以下の応援カメラマンを追加する
        addInvitation() {
            if (
                this.$helper.isNud(this.additional_other_partner.photographer_id) ||
                this.$helper.isNud(this.additional_other_partner.invitation_status
            )) {
                this.showErrorMessage('応援カメラマン名と依頼ステータスは必須です');
                return;
            }

            let new_partner = this.other_partners.find(other_partner => other_partner.photographer_id === this.additional_other_partner.photographer_id);

            let partner_invitation = {
                invitation_id: null,
                invitation_status: this.additional_other_partner.invitation_status,
                partner: new_partner,
                employee: new Employee(),
                input_date_display: '',
                memo: null,
                schedule_names: this.extractScheduleName(new_partner),
            }

            this.partner_invitations.push(partner_invitation);

            this.additional_other_partner.photographer_id = null;
            this.additional_other_partner.invitation_status = null;
        },
        writeInvitation() {
            this.invitations.splice(0);

            for (let partner_invitation of this.partner_invitations) {
                if (!this.$helper.isNud(partner_invitation.invitation_status)) {
                    let invitation = new Invitation();
                    invitation.invitation_id = partner_invitation.invitation_id;
                    invitation.partner = partner_invitation.partner;
                    invitation.invitation_date = this.date;
                    invitation.invitation_status = partner_invitation.invitation_status;
                    invitation.memo = partner_invitation.memo;

                    this.invitations.push(invitation);
                }
            }
        },
        save() {
            this.startScreenLoading();

            this.writeInvitation();

            const now = dateNow();

            this.$http.put(`/invitations`, {
                date: this.date,
                invitations_update_at: this.last_invitations_update_at,
                now: now,
                invitations: this.invitations
            })
            .then(response => {
                this.invitations.splice(0);
                this.partner_invitations.splice(0);

                for (let row of response.data) {
                    this.invitations.push(new Invitation(row));
                }
                this.last_invitations_update_at = now; // 自分の編集では競合しないため記録しておく
                this.showMessage('保存しました');
                this.$emit('update:modelValue', this.invitations);
                this.mergePartnersAndInvitations(this.invitations);
                this.$emit('update');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
